import React from 'react';
import "./loading.scss";

const Loading = () => {
  return (
    <div id="loading">
        <div className="spinner"></div>
    </div>
  )
}

export default Loading